import { del, get, post, put } from './fetchUtil'
import authService from './authService'
import { AudioFileSummary, AudioFileWithUrl, Mission, MissionMetadata, Observation } from '../redux/missionsModel';

export interface UpdateObservationResponse { version: string, ok: boolean, observation: Observation }

export interface FetchObservationsResponse {
  lastEvaluatedKey?: string
  items: Observation[]
}

export interface ApiResponse<E> {
  version: string
  item: E
}

export interface FetchResolutionResponse {
  buckets: number[]
  resolution: number
  index: number
}

// * global FLYDATA_AUDIO_WEB */
declare var FLYDATA_AUDIO_WEB: {
  api: string
  cognito: {
    userPoolId: string
    clientId: string
  }
}

class FlydataAudioApi {
  baseUrl: string;
  userToken: string | null;

  constructor() {
    this.baseUrl = FLYDATA_AUDIO_WEB.api
    this.userToken = null
  }

  setBaseUrl(_baseUrl: string) {
    this.baseUrl = _baseUrl;
  }

  setUserToken(_token: string) {
    this.userToken = _token;
  }

  async getUserToken() {
    const user = await authService.getCurrentUser()
    if (!user) {
      return null;
    }
    const token = user.token
    if (!token) {
      console.log('Error: no token')
      return null;
    }
    return token
  }

  async createMission(missionName: string) {
    return await put(this.baseUrl, "/missions", { name: missionName }, this.getUserToken)
  }

  async updateMissionMetadata(missionId: string, metadata: MissionMetadata) {
    const json = await post(this.baseUrl, `/missions/${missionId}`, metadata, this.getUserToken)
    return json
  }

  async fetchMissions(retryCount: number): Promise<Mission[]> {
    console.log('fetching missions');
    const json = await get(this.baseUrl, "/missions", null, this.getUserToken)
    return json.items;
  }

  async fetchMission(missionId: string): Promise<{ version: string, item: Mission }> {
    const json = await get(this.baseUrl, `/missions/${missionId}`, null, this.getUserToken)
    return json;
  }

  async addAudio(missionId: string, audioInfo: Record<string, any>) {
    console.log('ai', audioInfo);
    const json = await post(this.baseUrl, `/missions/${missionId}/audioFiles`, audioInfo, this.getUserToken)
    console.log('addAudio response', json);
    return json
  }

  async deleteAudioFile(missionId: string, audioFileId: string) : Promise<boolean> {
    try {
      await del(this.baseUrl, `/missions/${missionId}/audioFiles/${audioFileId}`, this.getUserToken)    
      return true
    } catch (error : any) {
      if (error.status === 400) {
        console.log('Error deleting audio file', error)
        return false
      }
      throw error
    }
  }

  async updateAudioMetadata(missionId: string, audioId: string, patch: Record<string, any>) {
    console.log('am', audioId, patch);
    const json = await put(this.baseUrl, `/missions/${missionId}/audiofiles/${audioId}`, patch, this.getUserToken)
    return json;
  }

  async getAudioUrl(missionId: string, audioId: string): Promise<ApiResponse<AudioFileWithUrl>> {
    const json = await get(this.baseUrl, `/missions/${missionId}/audioFiles/${audioId}`, null, this.getUserToken)
    return json
  }

  async fetchAudioMetadataSummary(missionId: string, audioId: string): Promise<ApiResponse<AudioFileSummary>> {
    return await get(this.baseUrl, `/missions/${missionId}/audioFiles/${audioId}/summary`, null, this.getUserToken)
  }

  async fetchAudioSummaryResolution(missionId: string, audioId: string, resolution: number, idx: number): Promise<FetchResolutionResponse> {
    const path = '/missions/' + missionId + '/audioFiles/' + audioId + '/summary/resolutions/' + resolution + '/' + idx
    const json: ApiResponse<{ buckets: number[] }> = await get(this.baseUrl, path, null, this.getUserToken)
    return {
      buckets: json.item.buckets,
      resolution: resolution,
      index: idx
    }
  }

  async storeObservation(missionId: string, audioId: string, observationData: Observation, tmpId?: any): Promise<{ refId: string, observationId: string }> {
    const json = await put(this.baseUrl, `/missions/${missionId}/audiofiles/${audioId}/observations`, observationData, this.getUserToken)
    return json.item
  }

  async fetchObservations(missionId: string, audioId: string): Promise<FetchObservationsResponse> {
    const path = '/missions/' + missionId + '/audiofiles/' + audioId + '/observations'
    const json = await get(this.baseUrl, path, null, this.getUserToken)
    return json
  }

  async deleteObservation(missionId: string, audioId: string, observationId: string) : Promise<void> {
    const path = `/missions/${missionId}/audioFiles/${audioId}/observations/${observationId}`
    await del(this.baseUrl, path, this.getUserToken)
  }

  async updateObservation(missionId: string, audioId: string, observationId: string, observation: Observation): Promise<UpdateObservationResponse> {
    const path = `/missions/${missionId}/audioFiles/${audioId}/observations/${observationId}`
    const json = await post(this.baseUrl, path, observation, this.getUserToken)
    return json
  }

  async exportMissionData(missionId: string) {
    const json = await get(this.baseUrl, `/missions/${missionId}/report`, null, this.getUserToken)
    return json
  }

  async exportMissionEnvironmentData(missionId: string) {
    const json = await get(this.baseUrl, `/missions/${missionId}/report/environment`, null, this.getUserToken)
    return json
  }
}

const instance = new FlydataAudioApi()

export default instance
