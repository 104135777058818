import { Button, ButtonGroup } from "@mui/material"
import flydataAudioApi from '../../services/flydataAudioApi'
import DownloadIcon from '@mui/icons-material/Download';
import { useState } from "react";


const ExportMissionComponent = ({ mission }) => {
  const [ downloading, setDonwloading ] = useState(false)

    const exportMission = async () => {
      setDonwloading(true)
        const data = await flydataAudioApi.exportMissionData(mission.id)
        console.log(data);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = data.signedUrl;
        a.download = mission.name ? mission.name + '.xlsx' : 'flydata_audio_export.xlsx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setDonwloading(false)
    }

    const exportMissionEnvironment = async () => {
      setDonwloading(true)
        const data = await flydataAudioApi.exportMissionEnvironmentData(mission.id)
        console.log(data);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = data.signedUrl;
        a.download = mission.name ? mission.name + '_environment.xlsx' : 'flydata_audio_environment_export.xlsx';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setDonwloading(false)
    }

  return (
    <ButtonGroup>
      <Button disabled={downloading} onClick={() => exportMission()}>
        Export <DownloadIcon sx={{ paddingLeft: 1}} /> </Button>
      <Button disabled={downloading} onClick={() => exportMissionEnvironment()}>
        Export Env <DownloadIcon sx={{ paddingLeft: 1}} /> </Button>
    </ButtonGroup>
  )
}

export default ExportMissionComponent
