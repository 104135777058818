import React from "react";

class PureCanvas extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.container = document.getElementById('dashboard-main')
  }

  shouldComponentUpdate() {
    return false;
  }

  handleClick = e => {
    var x;
    var y;
    if (e.pageX || e.pageY) {
      x = e.pageX;
      y = e.pageY;
    }
    x -= this.canvasRef.offsetLeft;
    y += this.container.scrollTop;

    this.props.onClick({ x, y });
  };

  render() {

    return (
      <canvas
        className={this.props.className}
        width={this.props.width}
        height={this.props.height}
        onClick={evt => this.handleClick(evt)}
        ref={node => {
          if (node) {
            this.props.contextRef(node.getContext("2d"));
            this.canvasRef = node;
          }
        }}
      />
    );
  }
}

export default PureCanvas;
