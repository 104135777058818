import { ForwardedRef, MutableRefObject, forwardRef, useState } from "react";
import Waveform from "./Waveform";
import { AppState } from "../../redux/reducers";
import { useSelector } from "react-redux";
import { Box, Button, ButtonGroup, Slider, Typography } from "@mui/material";
import useWindowSize from "../../useWindowSize";

export interface AudioComponentProps {
    url?: string;
}

const AudioComponent = forwardRef((props: AudioComponentProps, ref: ForwardedRef<HTMLAudioElement | null>) => {
    const { url } = props
    const [audioWidth, setAudioWidth] = useState<number>(45)
    const size = useWindowSize()
    const audioRef = ref as MutableRefObject<HTMLAudioElement | null>

    const { resolutionSummary } = useSelector((state: AppState) => state.audioFileEdit)

    if (!audioRef || !url || !size || !size.width) {
        return null;
    }

    const width = size.width
    const canvasWidth = width - 400

    return <>
        <Waveform
            className='waveform'
            height={350}
            audioWidth={audioWidth}
            width={canvasWidth}
            getAudioData={() => {
                if (!audioRef.current) {
                    return { currentTime: -1 }
                }
                return { currentTime: audioRef.current.currentTime, duration: audioRef.current.duration, summary: resolutionSummary }
            }}
            onSeek={(time: number) => {
                if (audioRef.current) {
                    audioRef.current.currentTime = time
                }
            }}
        />
        <div>
            <audio ref={audioRef} controls src={url} />
        </div>
        <Box sx={{ marginBottom: 2, align: 'right', marginRight: 0 }}
            onKeyDown={e => {
                console.log(e)
                if (!audioRef.current) {
                    return
                }
                const key = e.key
                if (key === ' ') {
                    if (audioRef.current.paused) {
                        audioRef.current.play()
                    } else {
                        audioRef.current.pause()
                    }
                }
                const isShift = Boolean(e.shiftKey)
                const offset = isShift ? 59 : 5
                const ct = audioRef.current.currentTime
                switch (key) {
                    case "ArrowLeft":
                        audioRef.current.currentTime = ct - offset
                        break;
                    case "ArrowRight":
                        // Right pressed
                        audioRef.current.currentTime = ct + offset
                        break;
                    case "1":
                        audioRef.current.playbackRate = 1
                        break;
                    case "2":
                        audioRef.current.playbackRate = 2
                        break;
                    case "3":
                        audioRef.current.playbackRate = 3
                        break;
                    case "4":
                        audioRef.current.playbackRate = 4
                        break;
                    default:
                        console.log(key)
                }
            }}>
            <Typography>Playback speed</Typography>
            <ButtonGroup>
                {[0.5, 0.75, 1, 1.25, 1.5].map((speed, idx) => {
                    return (<Button key={'playbackrate-' + idx} variant='contained' onClick={() => {
                        if (audioRef.current) {
                            audioRef.current.playbackRate = speed
                        }
                    }}>{speed}x</Button>)
                })
                }
            </ButtonGroup>
        </Box>
        <Box sx={{ maxWidth: 300 }}>
            <Typography>Zoom</Typography>
            <Slider
                value={audioWidth}
                onChange={(_, newValue) => {
                    setAudioWidth(newValue as number)
                }}
                min={20}
                max={500}
                step={5}
                valueLabelDisplay='off'
            />
        </Box>
    </>
})

export default AudioComponent

/* debug stuff
const [currentTime, setCurrentTime] = useState(0)
        <Button onClick={() => audioRef.current?.play()}>Play</Button>
        <Button onClick={() => audioRef.current ? audioRef.current.currentTime = 60 : undefined}>Goto 1 min</Button>
        <Button onClick={() => audioRef.current ? audioRef.current.currentTime = 15390 : undefined}>Goto 4:16:30</Button>
        <Button onClick={() => audioRef.current ? audioRef.current.currentTime = 16620 : undefined}>Goto 4:37:00</Button>
        
        <Button onClick={() => setCurrentTime(audioRef.current ? audioRef.current.currentTime : 0)}>Get current time</Button>
        <Typography>CurrentTime: {currentTime}</Typography>
*/