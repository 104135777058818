import React, { ReactElement } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  LinearProgress,
  TableCell,
  IconButton,
  Button
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { DateTime } from "luxon";

export type AlignEnum = 'left' | 'center' | 'right'

export interface FieldCellProps {
  title: string;
  source: string;
  sourceFn?: (item: Record<string, any>) => string;
  item?: Record<string, any>;
  align?: AlignEnum;
  minWidth?: number;
};


export interface FieldCellFnProps {
  title: string;
  source?: string;
  sourceFn: (item: Record<string, any>) => string;
  item?: Record<string, any>;
  align?: AlignEnum;
  minWidth?: number;
};

export type EditRowButtonProps<E> = FieldCellProps & {
  onClick: (item: E) => void;
};

export const EditRowButton = (props: EditRowButtonProps<any>) => {
  const { title, item, onClick } = props
  if (!item) {
    return <span>{title}</span>;
  }
  return (
    <IconButton color='inherit' sx={{ paddingTop: 0, paddingBottom: 0, paddingRight: 0 }} size='small' onClick={() => onClick(item)}>
      <EditIcon fontSize="small" />
    </IconButton>
  )
}


export type DeleteRowButtonProps<E> = {
  title: string;
  item?: E;
  onClick: (item: E) => void;
  disabledFn: (item: E) => boolean;
};

export const DeleteRowButton = (props: DeleteRowButtonProps<any>) => {
  const { disabledFn, title, item, onClick } = props
  if (!item) {
    return <span>{title}</span>;
  }
  return (
    <Button disabled={disabledFn(item)} sx={{ paddingTop: 0, paddingBottom: 0 }} variant="outlined" color='error' size='small' onClick={(event) => {
      event.stopPropagation();
      event.preventDefault();
      onClick(item);
    }}>
      <DeleteIcon fontSize="small" />
    </Button>
  )
}

// Function to select a property from an object. The property is selected by name and may be nested.
// Example: selectProperty({a: {b: 1}}, 'a.b') returns 1
export const selectProperty = (obj: Record<string, any>, path: string): (boolean | number | string | undefined) => {
  const parts = path.split('.')
  let res: (Record<string, any> | any) = obj
  for (let i = 0; i < parts.length; i++) {
    if (!res) {
      return undefined
    }
    res = res[parts[i]]
  }
  return res
}

export const TextFieldCell = (props: FieldCellProps | FieldCellFnProps) => {
  const { title, source, sourceFn, item } = props;
  if (!item) {
    return <span>{title}</span>;
  }
  let value;
  if (!source) {
    if (!sourceFn) {
      return <span>No source or sourceFn</span>
    }
    value = sourceFn(item)
  } else {
    value = selectProperty(item, source) || ''
  }
  return <span>{value}</span>;
};


export const NumberCell = (props: FieldCellProps | FieldCellFnProps) => {
  const { title, source, sourceFn, item } = props;
  if (!item) {
    return <span>{title}</span>;
  }
  let value;
  if (!source) {
    if (!sourceFn) {
      return <span>No source or sourceFn</span>
    }
    value = sourceFn(item)
  } else {
    value = selectProperty(item, source) || ''
  }
  return <span style={{ display: 'inline-block', paddingRight: 8, width: '100%', textAlign: 'right'}}>{value}</span>;
};



export const FileSizeCell = (props: FieldCellProps) => {
  const { title, source, item } = props;
  if (!item) {
    return <span>{title}</span>;
  }
  if (!source) {
    return <span>Source required</span>
  }
  const value = parseFloat(item[source])
  let strValue = ''
  if (value < 1024) {
    strValue = `<1 Kb`
  } else if (value < 1024 * 1024) {
    strValue = `${Math.floor(value / 1024)} Kb`
  } else {
    strValue = `${Math.floor(value / 1024 / 1024)} Mb`
  }
  return <span style={{ alignContent: 'right' }}>{strValue}</span>;
};

export const BooleanFieldCell = (props: FieldCellProps) => {
  const { title, source, item } = props;
  if (!item) {
    return <span>{title}</span>;
  }
  if (!source) {
    return <span>Source required</span>
  }

  return <span>{item[source] ? '1' : '0'}</span>;
};

export const UnixTimestampCell = (props: (FieldCellProps | FieldCellFnProps) & { unit?: string; }) => {
  const { title, source, sourceFn, item, unit } = props;
  if (!item) {
    return <span>{title}</span>;
  }
  if (!source && !sourceFn) {
    return <span>Source required</span>
  }

  const _v = source ? selectProperty(item, source) : sourceFn ? sourceFn(item) : undefined
  console.log(_v)
  if (typeof (_v) === 'undefined') {
    return <span></span>
  }
  if (typeof (_v) !== 'number') {
    return <span>NaN</span>
  }
  const unitMultiplier = (unit === 'ms') ? 1 : 1000;
  const value = DateTime.fromMillis(_v as number * unitMultiplier) // (item[source] as number * unitMultiplier)

  // Print the time as UTC with seconds
  //const time = value.toUTC().toFormat("yyyy-MM-dd HH:mm:ss");

  const time = value.setLocale('da').toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)
  if (time === 'Invalid DateTime') {
    console.log({ item, _v, value })
  }
  return <span>{time}</span>;
};


export const UnixTimeOnlyCell = (props: (FieldCellProps | FieldCellFnProps) & { utc?: boolean, unit?: string; }) => {
  const { title, source, sourceFn, item, unit, utc } = props;

  if (!item) {
    return <span>{title}</span>;
  }
  if (!source && !sourceFn) {
    return (<span>Source required</span>)
  }
  if (source && !item[source]) {
    return <span></span>
  }
  const _v = source ? selectProperty(item, source) : sourceFn ? sourceFn(item) : undefined
  if (typeof (_v) === 'undefined') {
    return <span></span>
  }
  const unitMultiplier = (unit === 'ms') ? 1 : 1000;
  const value = DateTime.fromMillis(unitMultiplier * parseInt(`${_v}`)) // (item[source] as number * unitMultiplier)
  if (utc) {
    return <span>{value.setZone('utc').setLocale('da').toLocaleString(DateTime.TIME_WITH_SECONDS)}</span>;
  } else {
    return <span>{value.setLocale('fr').toLocaleString(DateTime.TIME_WITH_SECONDS)}</span>;
  }
};

export const UnixDateOnlyCell = (props: FieldCellProps & { unit?: string; }) => {
  const { title, source, item, unit } = props;
  if (!item) {
    return <span>{title}</span>;
  }
  if (!source) {
    return (<span>Source required</span>)
  }
  const unitMultiplier = (unit === 'ms') ? 1 : 1000;
  if (!item[source]) {
    return <span></span>
  }
  const value = DateTime.fromMillis(item[source] as number * unitMultiplier) // (item[source] as number * unitMultiplier)
  return <span>{value.setLocale('fr').toLocaleString(DateTime.DATE_SHORT)}</span>;
};

export const ConditionalCell = (props: FieldCellProps & { children: ReactElement[] }) => {
  const { title, source, item, children } = props;
  if (!item) {
    return <span>{title}</span>
  }
  if (!source) {
    return (<span>Source required</span>)
  }
  const value = item[source]
  // const renderedChildren = children.filter(c => c.props.value === value)
  return (<>
    {children.map((c: ReactElement, idx: number) => React.createElement(c.type, { ...c.props, key: `cc_${idx}`, _value: value }))}
  </>)
}

export const ConditionEquals = (props: { case: (string | number), _value?: (string | number), children: ReactElement }) => {
  const child = props.children
  const caseWhen = props.case
  if (caseWhen !== props._value) {
    return null
  }
  return React.createElement(child.type, { ...child.props, value: props._value })
}

const rowStyle = {
  cursor: "pointer",
  backgroundColor: "#f8f8f8",
  "&:hover": {
    backgroundColor: "#eee",
  },
};
const highlightRowStyle = {
  cursor: "pointer",
  backgroundColor: "#e8e8f4",
  "&:hover": {
    backgroundColor: "#eeeee8",
  },
};

type HighlightFunction<T> = {
  (item: T): boolean;
}

type StaticDataListProps<T> = {
  visible?: boolean;
  loading?: boolean;
  dense?: boolean;
  items: T[];
  highlight?: HighlightFunction<T>;
  children: ReactElement[] | ReactElement;
  onClick?: (item: T) => void;
  offset?: number;
  limit?: number;
};

const createArray = function (size: number): string[] {
  let res = []
  for (let i = 0; i < size; i++) {
    res.push('');
  }
  return res
}

const StaticDataList = function <T>({
  dense,
  visible,
  loading,
  items,
  children,
  onClick,
  offset,
  limit,
  highlight
}: StaticDataListProps<T>) {
  const _visible = typeof (visible) === 'undefined' ? true : visible
  if (!_visible) {
    return null;
  }
  if (!items) {
    return null;
  }
  const _children: ReactElement[] = Array.isArray(children)
    ? children
    : [children];
  const _offset: number = offset && offset > 0 ? offset : 0
  const _limit: number = limit || limit === 0 ? limit : items.length

  const _items: T[] = items.slice(_offset, _offset + _limit)
  const emptyRows: string[] = createArray(_limit - _items.length)
  const _highlight: HighlightFunction<T> = highlight ? highlight : () => false

  return (
    <div style={{ width: '100%', maxWidth: '100%', overflowX: 'auto' }}>
      <Table size={dense ? 'small' : 'medium'}>
        <TableHead>
          <TableRow>
            {_children.map((c: ReactElement, idx: number) => (
              <TableCell key={idx}>{c}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (<TableRow />) :
            _items.map((i: T, idx: number) => (
              <TableRow key={idx} sx={_highlight(i) ? highlightRowStyle : rowStyle}>
                {_children.map((c: ReactElement, idx: number) => (
                  <TableCell key={idx} sx={{ textAlign: c.props.align || 'left', minWidth: c.props.minWidth }} onClick={() => (onClick ? onClick(i) : {})}>
                    {React.createElement(c.type, { ...c.props, item: i })}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          {
            emptyRows.map((i: string, idx: number) => (
              <TableRow key={`_${idx}_empty`}>
                {_children.map((c: ReactElement, idx: number) => (
                  <TableCell key={idx}>
                    <span style={{ display: 'inline-block' }}></span>
                  </TableCell>
                ))}
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
      {loading && <LinearProgress variant="indeterminate" />}
    </div>
  );
};

StaticDataList.defaultProps = {
  loading: false,
  visible: true
}

export default StaticDataList;
