import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Grid, LinearProgress, Typography } from '@mui/material'
import {
  deleteAudioFile,
  fetchMission,
  initMissionEdit,
  setShadowMeta,
  storeMeta,
  updateShadowMeta,
} from '../redux/missionEdit/missionEditActions'
import { AppState } from '../redux/reducers'
import StaticDataList, { DeleteRowButton, TextFieldCell, UnixTimestampCell } from '../components/datalist/StaticDataList'
import EditComponent, { TextFieldEdit } from '../components/edit/EditComponent'
import { Box } from '@mui/system'
import { StringListFieldEdit } from '../components/edit/ListFieldEdit'
import deepEqual from 'deep-equal'
import { AudioFile } from '../redux/missionsModel'
import UploadAudioComponent from '../components/UploadAudioComponent'
import ExportMissionComponent from '../components/export/ExportMissionComponent'
import RefreshIcon from '@mui/icons-material/Refresh';

const MissionEditPage: React.FC = () => {
  const navigate = useNavigate()
  const params = useParams()
  const dispatch = useDispatch()
  const { loading } = useSelector((state: AppState) => state.ui)
  const { mission, shadowMetadata } = useSelector((state: AppState) => state.missionEdit)
  useEffect(() => {
    console.log('MissionEditPage', params)
    if (!params.id) {
      console.log('no id')
      navigate('/')
      return
    }
    dispatch(initMissionEdit(params.id))
  }, [params, dispatch, navigate])

  if (!mission) {
    return <LinearProgress />
  }

  console.log({ mission })
  const metadataDirty = !deepEqual(mission.metadata, shadowMetadata)

  return (
    <>
      <Grid sx={{ padding: 1 }} container spacing={2}>
        <Grid item md={6} lg={7}>
          <Typography variant="h4" sx={{ padding: 2 }}>
            {mission.name}
          </Typography>
        </Grid>
        <Grid item md={6} lg={5}>     
          <ExportMissionComponent mission={mission} />                    
          <Button onClick={() => dispatch(fetchMission(mission.id))}>
            <RefreshIcon />
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" sx={{ padding: 1 }}>
            Audio files
          </Typography>
          <StaticDataList
            items={mission.audioFiles}
            onClick={(audioFile: AudioFile) => navigate(`/missions/${mission.id}/audiofile/${audioFile.id}`)}
          >
            <TextFieldCell title={'Name'} source={'name'} />
            <TextFieldCell title={'Id'} source={'id'} />
            <TextFieldCell title={'Status'} source={'status'} />
            <UnixTimestampCell title={'Start time'} source={'metadata.startTime'} unit="ms" />
            <DeleteRowButton
              title='Delete'
              disabledFn={() => false}
              onClick={(audioFile: AudioFile) => {
                dispatch(deleteAudioFile(mission.id, audioFile.id))
              }} />
          </StaticDataList>
          <UploadAudioComponent missionId={mission.id} />
        </Grid>
        <Grid item xs={12}>
          <Box>
            <EditComponent
              title="Flight details"
              disabled={loading}
              dirty={metadataDirty}
              save
              cancel
              item={shadowMetadata}
              onChange={(prop: string, value: any) => dispatch(updateShadowMeta(prop, value))}
              onSave={() => {
                shadowMetadata && dispatch(storeMeta(mission.id, shadowMetadata))
              }}
              onCancel={() => {
                dispatch(setShadowMeta(mission.metadata))
              }}
            >
              <TextFieldEdit title="Callsign" source="callsign" />
              <TextFieldEdit title="Pilot" source="pilot" />
              <StringListFieldEdit title="Observers" source="observers" prop="name" />
            </EditComponent>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default MissionEditPage
